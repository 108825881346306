<template lang="pug">
    .death-stats-wrap
        .full-width
            .inner
                img.icon(src="../../../../../assets/images/articles/6/death_stats/icon.svg", alt="", loading="lazy", aria-hidden="true")
                ui-paragraph.text(v-html="locales.fullWidth")
        .chart.content
            ui-title(component="p", variant="subtitle", v-html="locales.chart.title")
            img.chart-image(src="../../../../../assets/images/articles/6/death_stats/chart.svg", alt="", loading="lazy", aria-hidden="true")
            ui-paragraph(v-html="locales.chart.source")
            .chart-text
                img.icon(
                    src="../../../../../assets/images/articles/6/death_stats/text_icon.svg",
                    alt="",
                    loading="lazy",
                    aria-hidden="true"
                )
                .texts
                    ui-paragraph(
                        v-for="text in locales.chart.text",
                        :key="text",
                        v-html="text"
                    )
        article-divider(
            image="empty"
            imageType="svg",
            :currentArticleId="6"
        )
        .top.content
            article-icon.top-icon-illn(src="hex_green", class="opacity-50 pulse")
            article-icon.top-icon-illn(src="hex_green", class="opacity-50 pulse")
            ui-title(component="p", variant="subtitle", v-html="locales.top.title")
            img.top-image(src="../../../../../assets/images/articles/6/death_stats/top.svg", alt="", loading="lazy", aria-hidden="true")
            img.top-illn(src="../../../../../assets/images/articles/6/death_stats/bacteria.png", alt="", loading="lazy", aria-hidden="true")
            .top-text
                ui-paragraph.text(
                    v-for="text in locales.top.text",
                    :key="text",
                    v-html="text"
                )
        article-divider(
            image="empty"
            imageType="svg",
            :currentArticleId="6"
        )
        .graph.content
            ui-title(component="p", variant="subtitle", v-html="locales.graph")
            img.graph-image(src="../../../../../assets/images/articles/6/death_stats/graph.svg", alt="", loading="lazy", aria-hidden="true")
</template>

<script>
import ArticleIcon from '../../../Icon.vue';
import ArticleDivider from '../../../Divider.vue';
import UiParagraph from '../../../../ui/Paragraph.vue';
import UiTitle from '../../../../ui/Title.vue';

export default {
    name: 'death-stats',
    components: {
        ArticleIcon,
        ArticleDivider,
        UiParagraph,
        UiTitle,
    },
    computed: {
        /* eslint-disable max-len */
        locales() {
            return {
                fullWidth: 'Туберкулез&nbsp;&mdash; самая смертоносная инфекция, по&nbsp;крайней мере, за&nbsp;последние два столетия. За&nbsp;этот период она унесла миллиард жизней&nbsp;&mdash; гораздо больше, чем оспа, малярия, чума, грипп, холера и&nbsp;СПИД.',
                chart: {
                    title: 'Смертность от&nbsp;некоторых инфекционных заболеваний <em>за&nbsp;последние 200 лет</em>',
                    source: 'Источник: Tom Paulson. Epidemiology: A&nbsp;mortal foe. Nature, vol.&nbsp;502, October 2013.',
                    text: [
                        'Сегодня общемировая смертность от&nbsp;туберкулеза составляет около 1,5 млн человек в&nbsp;год. Это существенно меньше, чем 200 лет назад, когда от&nbsp;чахотки ежегодно умирало по&nbsp;5&ndash;7 млн человек.',
                        '<em>Туберкулез в&nbsp;течение нескольких лет входит в&nbsp;10&nbsp;ведущих причин смерти в&nbsp;мире.</em> Таким образом, несмотря на&nbsp;прогресс в&nbsp;лечении туберкулеза, он&nbsp;остается самым смертоносным инфекционным заболеванием.',
                    ],
                },
                top: {
                    title: '<em>Топ-10 причин смерти</em><br /> в мире в&nbsp;2017&nbsp;году, млн чел.',
                    text: [
                        'В&nbsp;течение последних пяти лет Россия была мировым лидером по&nbsp;темпам снижения смертности от&nbsp;туберкулеза. Тем не&nbsp;менее, у&nbsp;нас в&nbsp;стране она по-прежнему выше, чем в&nbsp;странах Западной Европы.',
                        'Трудно сказать, сохранится&nbsp;ли тенденция к&nbsp;снижению этого показателя в&nbsp;текущем году, в&nbsp;условиях пандемии коронавируса и&nbsp;стресса системы здравоохранения.',
                    ],
                },
                graph: '<em>Смертность от&nbsp;туберкулеза</em><br /> в России на&nbsp;100&nbsp;тыс. человек',
            };
        },
        /* eslint-enable max-len */
    },
};
</script>

<style lang="scss" scoped>
.death-stats-wrap {
    width: 100%;
    margin: rem(124) 0;

    img {
        width: 100%;
        object-fit: contain;
    }

    .content {
        width: 100%;
        max-width: 750px;
        margin: rem(58) auto;
    }

    .full-width {
        width: 100%;
        padding: rem(58) 0;
        background-color: rgba(212, 238, 235, 0.575);

        .inner {
            width: 100%;
            max-width: 750px;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;

            .icon {
                width: rem(60);
            }

            .text {
                margin-left: rem(24);
            }
        }
    }

    .chart {
        .chart-image {
            width: 100%;
            margin: rem(12) 0 rem(48) 0;
            display: block;
        }

        .chart-text {
            width: 100%;
            margin: rem(48) 0;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            .icon {
                width: rem(142);
            }

            .texts {
                width: calc(100% - #{rem(142)});
                padding-left: rem(24);

                > p {
                    margin-bottom: rem(24);
                }
            }
        }
    }

    .top {
        position: relative;

        .top-image {
            width: 100%;
            margin: rem(32) 0;
            display: block;
        }

        .top-illn {
            width: rem(342);
            margin-left: rem(84);
            display: block;
        }

        .top-text {
            width: rem(240);
            padding: rem(24);
            position: absolute;
            bottom: 0;
            right: 0;
            background-color: var(--light-green);

            .text {
                margin-bottom: rem(24);
            }
        }

        .top-icon-illn {
            position: absolute;

            &:nth-child(1) {
                width: rem(26);
                height: rem(22);
                top: 5%;
                left: 65%;
            }

            &:nth-child(2) {
                width: rem(46);
                height: rem(40);
                top: 70%;
                right: -20%;
            }
        }
    }

    .graph {
        .graph-image {
            margin-top: rem(64);
            display: block;
        }
    }

    @media (--viewport-tablet) {
        margin: rem-mobile(32) 0;

        .content {
            max-width: unset;
            margin: rem-mobile(48) auto;
            padding: 0 var(--offset-x);
        }

        .full-width {
            padding: rem-mobile(24) 0;

            .inner {
                width: 100%;
                max-width: unset;
                padding: 0 var(--offset-x);
                display: block;

                .icon {
                    width: rem-mobile(60);
                }

                .text {
                    margin-top: rem-mobile(24);
                    margin-left: 0;
                }
            }
        }

        .chart {
            .chart-image {
                margin: rem-mobile(24) 0 rem-mobile(48) 0;
            }

            .chart-text {
                margin: rem-mobile(48) 0;
                display: block;

                .icon {
                    width: 50%;
                }

                .texts {
                    width: 100%;
                    padding-left: 0;

                    > p {
                        margin: rem-mobile(24) 0;
                    }
                }
            }
        }

        .top {
            .top-image {
                margin: rem-mobile(32) 0;
            }

            .top-illn {
                width: 100%;
                max-width: var(--illn-max-width);
                margin: 0 auto;
            }

            .top-text {
                width: 100%;
                padding: 0;
                position: static;
                background-color: transparent;

                .text {
                    margin: rem-mobile(24) 0;
                }
            }

            .top-icon-illn {
                display: none;
            }
        }

        .graph {
            .graph-image {
                margin-top: rem-mobile(64);
            }
        }
    }
}
</style>
